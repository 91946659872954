import * as Sentry from "@sentry/browser"
import { onDomContentLoaded } from "./utils"

export function setUpSentry() {
  const config = document.getElementById("sentry")

  Sentry.init({
    debug: config.getAttribute("data-debug") === "True",
    dsn: config.getAttribute("data-dsn"),
    environment: config.getAttribute("data-environment"),
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({ enableInp: true }),
    ],
    release: config.getAttribute("data-release"),
    replaysSessionSampleRate: Number(
      config.getAttribute("data-replay-session-sample-rate"),
    ),
    replaysOnErrorSampleRate: Number(
      config.getAttribute("data-replay-error-sample-rate"),
    ),
    tracesSampleRate: Number(config.getAttribute("data-traces-sample-rate")),
  })

  Sentry.setUser({
    id: config.getAttribute("data-user-id"),
    email: config.getAttribute("data-user-email"),
  })
}

onDomContentLoaded(setUpSentry)
